import request from "./request";

// 发送登录验证码
export function getSmsCode(params) {
    return request({
        url: "/api/general-user/account/send-code",
        method: "post",
        data: params
    });
}

// 注册
export function signUp(params) {
    return request({
        url: "/api/general-user/account/sign-up",
        method: "post",
        data: params
    });
}

// 重置密码
export function resetPassword(params) {
    return request({
        url: "/api/general-user/account/reset-password",
        method: "post",
        data: params
    });
}

// 登录
export function signIn(params) {
    return request({
        url: "/api/general-user/account/sign-in",
        method: "post",
        data: params
    });
}
// 登录
export function getUserInfo(params) {
    return request({
        url: "/api/general-user/account/info",
        method: "get",
        params
    });
}



// 用户上报数据(普通用户)
export function userUploadInfo(params) {
    return request({
        url: "/api/general-user/questionnaire",
        method: "post",
        data: params
    });
}

// 获取上报数据列表(普通用户+社区医生)
export function getUserInfoList(params) {
    return request({
        url: "/api/general-user/questionnaire",
        method: "get",
        params
    });
}



// 备注上报信息(社区医生)
export function remarkUpload(params) {
    return request({
        url: "/api/general-user/questionnaire/doctor-memo",
        method: "put",
        data: params
    });
}

// 赋予上报信息条形码(社区医生)
export function qrCodeUpload(params) {
    return request({
        url: "/api/general-user/questionnaire/bar-code",
        method: "put",
        data: params
    });
}



// 创建上报信息回执(医院医生)
export function createInfoUpload(params) {
    return request({
        url: "/api/general-user/questionnaire/result",
        method: "post",
        data: params
    });
}
// 创建上报信息回执(医院医生)
export function mohuSearch(params) {
    return request({
        url: "/api/general-user/questionnaire/search-bar-code",
        method: "get",
        params
    });
}


// 根据条形码获取上报数据列表(医院医生)
export function getUploadInfoList(params) {
    return request({
        url: "/api/general-user/questionnaire/bar-code",
        method: "get",
        params
    });
}


// 获取社区列表
export function getCommunityList(params) {
    return request({
        url: "/api/general-admin/community",
        method: "get",
        params
    });
}

// 删除上报信息(用户+社区医生)
export function deleteCommunityOrUser(params) {
    return request({
        url: "/api/general-user/questionnaire",
        method: "delete",
        params
    });
}
