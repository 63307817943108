import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/common.less'
import {
    Loading,
    Lazyload,
    Button,
    Toast,
    Dialog,
    Cell,
    CellGroup,
    Uploader ,
    ImagePreview  ,
    Tab,
    Tabs,
    Form,
    Field,
    Checkbox,
    RadioGroup,
    Radio,
    Picker,
    Popup
} from "vant";
// 默认vant组件
[Loading, Lazyload,Button,Toast,Dialog,Cell,CellGroup,Uploader,ImagePreview,Tab,Tabs,Checkbox, RadioGroup, Radio,Form,Field,Picker,Popup].forEach((item) => Vue.use(item));

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
