<template>
  <div class="person-content">
    <div class="person-header">
      <div class="person-header-text">个人中心</div>
      <div class="user-info">
        <div class="user-main">
          <div class="user-logo">
            <img v-if="userInfo.role === 0" src="../assets/user.png"
                 style="width: 100%; height: 100%; vertical-align: bottom" alt="">
            <img v-if="userInfo.role === 1" src="../assets/doctor.png"
                 style="width: 100%; height: 100%; vertical-align: bottom" alt="">
            <img v-if="userInfo.role === 2" src="../assets/gcs.png"
                 style="width: 100%; height: 100%; vertical-align: bottom" alt="">
          </div>
          <div class="user-real-info">
            <div class="user-name">{{ userInfo.nickname }}</div>
            <!--                        <div class="user-age">{{ userInfo.role }}</div>-->
          </div>
        </div>
        <div class="exit-login" @click="exitLogin">退出登录</div>
      </div>
    </div>
    <div v-if="userInfo.role == 2 || userInfo.role == 1" class="search-box">
      <div class="match-list" v-if="showTips">
        <div class="match-item" v-for="item in showList" :key="item.id" @click="chooseBarCode(item.bar_code)">
          {{ item.bar_code }}
        </div>
      </div>
      <div class="arrow-box" @click="showPopFunc">{{ (selectOptions.find((item) => {
        return item.value == selectKey
      }))?.label }} <span class="arrow"></span></div>
      <input type="text" v-model="selectValue" @input="handleInput">
      <div class="search-btn" @click="searchResult">搜索</div>
    </div>
    <div v-if="userInfo.role == 1" class="tabs">
      <div class="tab" :class="chooseTab1 == 0 ? 'choosed-tab' : ''" @click="chooseTabFunc1(0)">未添加条码</div>
      <div class="tab" :class="chooseTab1 == 1 ? 'choosed-tab' : ''" @click="chooseTabFunc1(1)">已添加条码</div>
      <div class="tab" :class="chooseTab1 == 2 ? 'choosed-tab' : ''" @click="chooseTabFunc1(2)">已出结果</div>
    </div>
    <div :class="userInfo.role==1 ? 'person-main1' : (userInfo.role==2 ? 'person-main2' : 'person-main0')">
      <div class="person-list" v-if="userInfo.role==0">
        <div class="perseon-item" @click="userJump(item)" v-for="item in list" :key="item.id">
          <div class="person-item-filed">
            <div class="person-label">姓名：</div>
            <div class="person-value">{{ item.patient_name }}</div>
          </div>
          <div class="person-item-filed">
            <div class="person-label">报告地点：</div>
            <div class="person-value">{{ communityMap[item.community_id] }}</div>
          </div>
          <div class="person-item-filed">
            <div class="person-label">检查时间：</div>
            <div class="person-value">{{ item.create_datetime }}</div>
          </div>
          <div class="person-item-filed">
            <div class="person-label">检查结果：</div>
            <div class="person-value"
                 v-html="item.result_info.id ? statusFilter(item) : (item.bar_code ? '请等候医院检查结果' : '请等候社区添加条形码')"></div>
          </div>
          <div class="person-item-filed">
            <div class="person-label">结果时间：</div>
            <div class="person-value">{{ item.result_info.id ? item.result_info.create_datetime : '请等候' }}
            </div>
          </div>
          <div class="person-delete" v-if="!item.bar_code" @click.stop="deleteReport(item)">删除</div>
        </div>
      </div>
      <div class="person-list" v-if="userInfo.role==1">
        <div class="perseon-item" @click="jumpTo(item)" v-for="item in list" :key="item.id">
          <div class="person-item-filed between-item">
            <div class="person-label">{{ item.patient_name }}</div>
          </div>
          <div class="person-item-filed">
            <div class="person-label">用户手机号：</div>
            <div class="person-value">{{ item.patient_phone }}</div>
          </div>
          <div class="person-item-filed">
            <div class="person-label">基本信息：</div>
            <div class="person-value">{{ item.patient_age }}岁</div>
          </div>
            <div class="person-item-filed">
                <div class="person-label">检查结果：</div>
                <div class="person-value"
                     v-html="item.result_info.id ? statusFilter(item) : (item.bar_code ? '请等候医院检查结果' : '请等候社区添加条形码')"></div>
            </div>
          <div class="person-item-filed">
            <div class="person-label">提交社区：</div>
            <div class="person-value">{{ communityMap[item.community_id] }}</div>
          </div>
          <div class="person-item-filed">
            <div class="person-label">提交时间：</div>
            <div class="person-value">{{ item.update_datetime }}</div>
          </div>
          <div class="person-delete" v-if="!item.bar_code" @click.stop="deleteReport(item)">删除</div>
        </div>
      </div>
      <div class="person-list" v-if="userInfo.role==2">
        <div class="perseon-item" @click="jumpToFixed(item)" v-for="item in list" :key="item.id">
          <div class="person-item-filed between-item">
            <div class="person-label">{{ item.patient_name }}</div>
            <div class="person-value" v-if="item.result_info && item.result_info.id">已完成</div>
            <div class="person-value" style="color: red;" v-else>未完成</div>
          </div>
            <div class="person-item-filed">
                <div class="person-label">用户手机号：</div>
                <div class="person-value">{{ item.patient_phone }}</div>
            </div>
          <div class="person-item-filed">
            <div class="person-label">基本信息：</div>
            <div class="person-value">{{ item.patient_age }}岁</div>
          </div>
          <div class="person-item-filed">
            <div class="person-label">提交社区：</div>
            <div class="person-value">{{ communityMap[item.community_id] }}</div>
          </div>
          <div class="person-item-filed">
            <div class="person-label">提交时间：</div>
            <div class="person-value">{{ item.update_datetime }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="person-btn" @click="addItem" v-if="userInfo.role==0">{{ getBtnName(userInfo.role) }}</div>
    <div class="person-btn" @click="addItem2" v-if="userInfo.role==2">{{ getBtnName(userInfo.role) }}</div>
    <div class="form-content">
      <van-popup v-model="showPop" position="bottom">
        <van-picker show-toolbar value-key="label" @confirm="onConfirm" @cancel="onCancel" :columns="selectOptions" />
      </van-popup>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState } from 'vuex'
import { setToken, setUserInfo } from '@/utils/utils'
import { deleteCommunityOrUser, getCommunityList, getUserInfo, getUserInfoList, mohuSearch } from '@/api/apis'
import { Dialog, Toast } from 'vant'

export default {
  name: 'PersonalCenter',
  components: {},
  computed: {
    ...mapState(['userInfo'])
  },
  data() {
    return {
      list: [],
      selectOptions: [
        {
          label: '条形码',
          value: 2
        },
        {
          label: '手机号',
          value: 1
        },
        {
          label: '姓名',
          value: 3
        }
      ],
      communityMap: {},
      result_switch: 0,
      chooseTab: 0,
      chooseTab1: 0,
      showPop: false,
      selectKey: 2,
      selectValue: '',
      showTips: false,
      showList: []

    }
  },
  methods: {
    handleInput(event) {
      console.log(event.target.value)
      if (this.selectKey === 2) {
        this.getTipList(event.target.value)
      }
    },

    searchResult() {
      this.getList()
    },
    onConfirm(value) {
      console.log(value)
      this.selectKey = value.value
      this.showPop = false
      this.showTips = false
    },
    onCancel(value) {
      console.log(value)
      this.showPop = false
    },
    showPopFunc() {
      this.showPop = true
    },
    chooseBarCode(code) {
      this.selectValue = code
      this.showTips = false
      this.showList = []
    },
    jumpTo(item) {
      if (!item.bar_code) {
        this.$router.push('/communityForm?obj=' + JSON.stringify(item))
      } else {
        this.$router.push('/showForm?obj=' + JSON.stringify(item))
      }
    },
    // y用户列表的跳转
    userJump(item) {
      this.$router.push('/showForm?obj=' + JSON.stringify(item))
    },
    jumpToFixed(item) {
      // 检查已完成的跳转
      if (item.result_info && item.result_info.id) {
        this.$router.push('/doctorForm?hiddenSearch=1&obj=' + JSON.stringify(item))
      } else {
        this.$router.push('/doctorForm?obj=' + JSON.stringify(item))
      }
    },
    statusFilter(item) {
      if (item.result_info.practical_value >= 0 && item.result_info.practical_value <= 4) {
        return '正常'
      } else {
        return '<span style="color: red;">↑</span>'
      }
    },
    chooseTabFunc(tab) {
      this.chooseTab = tab
      this.result_switch = tab
      this.getList()
    },
    chooseTabFunc1(tab) {
      this.chooseTab1 = tab
      this.getList()
    },
    addItem() {
      this.$router.push('/userForm')
    },
    addItem2() {
      this.$router.push('/doctorForm')
    },
    getBtnName(val) {
      let map = {
        0: '添加新筛查',
        2: '添加报告结果'
      }
      return map[val]
    },
    exitLogin() {
      setToken('')
      setUserInfo(null)
      this.$store.commit('setUserInfo', null)
      this.$store.commit('setUserToken', '')
      this.$router.replace('login')
    },
    getList() {
      let obj = {}
      if (this.userInfo.role == 2 || this.userInfo.role == 1) {
        if (this.selectKey === 1) {
          obj['patient_phone'] = this.selectValue
        } else if (this.selectKey === 2) {
          obj['bar_code'] = this.selectValue
        } else if (this.selectKey === 3) {
          obj['patient_name'] = this.selectValue
        }
      }
      if (this.userInfo.role == 1) {
          if (this.chooseTab1 == 0) {
              obj['bar_code_switch'] = 0
              obj['result_switch'] = 0
          } else if (this.chooseTab1 == 1) {
              obj['result_switch'] = 0
              obj['bar_code_switch'] = 1
          } else if (this.chooseTab1 == 2) {
              obj['result_switch'] = 1
          }
      }
      getUserInfoList(obj).then((res) => {
        console.log(res)
          this.list = res

      }).catch((e) => {
        console.log(e)
        Toast.fail(e.msg || '系统错误')
      })
    },
    getTipList(code) {
      mohuSearch({
        bar_code: code
      }).then((res) => {
        console.log(res)
        if (res.length) {
          this.showTips = true
          this.showList = res || []
        } else {
          this.showTips = false
        }
      }).catch((e) => {
        console.log(e)
        Toast.fail(e.msg || '系统错误')
      })
    },
    deleteReport(item) {
      let _this = this
      Dialog.confirm({
        title: '提示',
        message: '确认删除此条数据?'
      }).then(() => {
        deleteCommunityOrUser({
          id: item.id
        }).then((res) => {
          console.log(res)
          _this.getList()
        }).catch((e) => {
          console.log(e)
          Toast.fail(e.msg || '系统错误')
        })
      }).catch(() => {
        // on cancel
      })
    },
    getCommunityListFunc() {
      getCommunityList().then((res) => {
        console.log(res)
        let arr = {}
        res.forEach(item => {
          if (!arr[item.id]) {
            arr[item.id] = item.title
          }
        })
        this.communityMap = arr
      }).catch((e) => {
        console.log(e)
        Toast.fail(e.msg || '系统错误')
      })
    },
    getUserInfoFunc() {
      getUserInfo().then((res) => {
        console.log(res)
        setUserInfo(res)
        this.$store.commit('setUserInfo', res)
      }).catch((e) => {
        console.log(e)
        Toast.fail(e.msg || '系统错误')
      })
    }
  },
  mounted() {
    // if (this.userInfo.role == 0) {
    //
    // }
    this.getCommunityListFunc()
    this.getList()
    // 每次进入个人中心都要刷新信息
    this.getUserInfoFunc()
  }
}
</script>
<style scoped lang="less">
.person-content {
  background: #F5F7FB;
  height: 100vh;

  .tabs {
    display: flex;
    padding: 15px 0;
    justify-content: space-around;

    .tab {
      height: 49px;
      width: 150px;
      border-bottom: 1px solid transparent;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
    }

    .choosed-tab {
      border-bottom: 1px solid #49B9AD;
      color: #49B9AD;
    }
  }

  .search-box {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 32px;
    padding: 0 25px;
    margin: 0 auto;
    position: relative;

    .match-list {
      position: absolute;
      top: 50px;
      background: #ddd;
      left: 160px;
      max-height: 200px;
      overflow-y: auto;
      //width: 100%;
      z-index: 99;

      .match-item {
        height: 60px;
        border-bottom: 1px solid #fff;
        padding: 0 40px;
        line-height: 60px;
      }
    }

    .arrow-box {
      position: relative;
      padding-right: 20px;
    }

    input {
      width: 400px;
      margin: 0 30px;
    }

    .arrow {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid black;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translate(-50% -50%);
    }

    .search-btn {
      background: #49B9AD;
      border-radius: 15px;
      color: #fff;
      padding: 5px 25px;
      font-size: 26px;
    }
  }

  .person-btn {
    margin: 20px auto;
    background: #4AC0C8;
    border-radius: 20px 20px 20px 20px;
    width: 702px;
    height: 60px;
    line-height: 60px;
    font-weight: 400;
    font-size: 32px;
    color: #FFFFFF;
  }

  .person-main0 {
    width: 100%;
    height: calc(100vh - 430px);
    background: #ffffff;
  }

  .person-main1 {
    width: 100%;
    height: calc(100vh - 450px);
    background: #ffffff;
  }

  .person-main2 {
    width: 100%;
    //height: calc(100vh - 550px);
    height: calc(100vh - 470px);
    background: #ffffff;
  }

  .person-list {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .perseon-item {
      display: flex;
      width: 660px;
      //height: 270px;
      box-sizing: border-box;
      padding: 30px;
      margin: 0 45px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      position: relative;

      font-size: 32px;
      color: #1C2023;
      line-height: 36px;
      border-bottom: 1px solid #ddd;

      .person-delete {
        position: absolute;
        right: 30px;
        padding: 5px 20px;
        border-radius: 30px;
        bottom: 30px;
        border: 1px solid red;
        color: red;
      }

      .person-item-filed {
        display: flex;
        align-items: center;
          margin-bottom: 10px;
      }

      .between-item {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .has-qrcode {
    font-weight: 400;
    font-size: 20px;
    color: #FA541C;
    background: #FFF2E8;
    border-radius: 10px 10px 10px 10px;
    padding: 0 10px;
  }

  .no-qrcode {
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    background: #E3E4E5;
    border-radius: 10px 10px 10px 10px;
    padding: 0 10px;

  }

  .user-main {
    display: flex;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
  }

  .user-real-info {
    color: #1C2023;
    margin-left: 20px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 0;

    .user-name {
      font-size: 34px;
      color: #1C2023;
    }

    .user-age {
      font-size: 32px;
      color: #606972;
      text-align: left;
    }
  }

  .user-logo {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    //background: red;
  }

  .person-header {
    font-size: 38px;
    margin-bottom: 120px;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    height: 200px;
    background: #49B9AD;
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .person-header-text {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
    }

    .user-info {
      position: absolute;
      bottom: 0;
      width: 702px;
      height: 180px;
      background: #FFFFFF;
      border-radius: 20px 20px 20px 20px;
      transform: translateY(50%);

      .exit-login {
        height: 34px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 30px;
        color: #49B9AD;
        line-height: 34px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 22px;
        background: rgba(73, 185, 173, 0.2);
        border-radius: 25px 0px 0px 25px;
      }
    }
  }
}
</style>
