import Vue from 'vue'
import Vuex from 'vuex'
import {getToken} from "@/utils/utils";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      token: getToken() || '',
  },
  getters: {
  },
  mutations: {
      setUserInfo(state, params) {
          state.userInfo = { ...params };
      },
      setUserToken(state, token) {
          state.token = token;
      },
  },
  actions: {
  },
  modules: {
  }
})
