import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PersonalCenter from '../views/PersonalCenter.vue'
import {getToken} from "@/utils/utils";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: PersonalCenter
  },
  {
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    component: PersonalCenter
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/userForm',
    name: 'userForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/userForm.vue')
  },
  {
    path: '/clearCache',
    name: 'clearCache',
    component: () => import(/* webpackChunkName: "about" */ '../views/test.vue')
  },
  {
    path: '/communityForm',
    name: 'communityForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/communityForm.vue')
  },
  {
    path: '/doctorForm',
    name: 'doctorForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/doctorForm.vue')
  },
  {
    path: '/showForm',
    name: 'showForm',
    component: () => import(/* webpackChunkName: "about" */ '../views/showForm.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    if (['login', 'register'].includes(to.name)) {
        // removeToken();
        next();
    } else if (!getToken() && to.name !== 'login') {
        next({ path: '/login'});
    } else {
        next();
    }
})

export default router
