export function isMobile(val) {
    return /^1[3456789]\d{9}$/.test(val);
}
export function setToken(val) {
    localStorage.setItem("token", val)
}
export function setUserInfo(val) {
    localStorage.setItem("userInfo", JSON.stringify(val))
}
export function getUserInfo() {
    return JSON.parse(localStorage.getItem("userInfo"))
}
export function getToken() {
    return localStorage.getItem("token")
}
